/* eslint-disable no-nested-ternary */
import { RootState } from 'store'
import { ActionTree, MutationTree, GetterTree } from 'vuex'

import { catalog, unitiSource, unitiConversationId } from '~/store/api'
import CatalogWrapper from '~/lib/catalog/CatalogWrapper'
import { Catalog as CatalogResponse } from '~/lib/catalog/interfaces'
import FilteredCatalogWrapper from '~/lib/catalog/FilteredCatalogWrapper'
import { Brand } from '~/lib/common/brand'
import { Catalog, Hardware, Plan } from '~/store/interfaces'
import { v4 as uuidv4 } from 'uuid';

export const name = 'catalog'

const networks: { name: string, priority: number }[] = [
  {
    name: 'OC',
    priority: 0
  },
  {
    name: 'LB',
    priority: 1
  },
  {
    name: 'ON',
    priority: 2
  },
  {
    name: 'NB',
    priority: 3
  },
  {
    name: 'OF',
    priority: 3
  },
  {
    name: 'SM',
    priority: 4
  },
  {
    name: 'SMP',
    priority: 5
  },
  {
    name: 'WIR',
    priority: 6
  },
  {
    name: 'HW',
    priority: 7
  },
  {
    name: 'HX',
    priority: 7
  },
  {
    name: 'RT',
    priority: 8
  },
  {
    name: 'UFL',
    priority: 9
  },
  {
    name: 'UW',
    priority: 10
  }
]

export const namespaced = true
export interface State {
  catalogs: CatalogResponse[]
  planType: string
  catalogType: string
  planGroup: string
  filteredCatalogWrapper: FilteredCatalogWrapper | null
}

export const state = (): State => ({
  catalogs: [],
  planType: 'Residential',
  catalogType: 'Residential',
  planGroup: null,
  filteredCatalogWrapper: null
})

export const getters: GetterTree<State, RootState> = {
  internetCatalogs: (state): Catalog[] => state.filteredCatalogWrapper ? state.filteredCatalogWrapper.internet() : [],
  plans: (state): Plan[] => state.filteredCatalogWrapper ? state.filteredCatalogWrapper.wrapper().plans() : [],
  voicePlans: (state): Catalog[] => state.filteredCatalogWrapper ? state.filteredCatalogWrapper.wrapper().voicePlans() : [],
  filteredPlans: (state, _, rootState, rootGetters): Plan[] => state.filteredCatalogWrapper ? state.filteredCatalogWrapper.plans(rootGetters['sq/primaryNetwork'], rootGetters['cart/promo'], rootGetters['sq/isBusiness'] ? 'Business' : 'Residential', state.planGroup) : [],
  defaultPlan: (state, _, rootState, rootGetters): Plan | null => state.filteredCatalogWrapper?.defaultPlan(rootGetters['sq/primaryNetwork'],  rootGetters['sq/isBusiness'] ? 'Business' : 'Residential', state.planGroup),
  planType: state => state.planType,
  hardwares: (state): Hardware[] => state.filteredCatalogWrapper ? state.filteredCatalogWrapper.wrapper().hardware() : [],
  filteredHardware: (state, getters, rootState, rootGetters): Hardware[] => state.filteredCatalogWrapper ? state.filteredCatalogWrapper.hardware(rootGetters['sq/primaryNetwork'], rootGetters['cart/voice'], rootGetters['sq/isBusiness'] ? 'Business' : 'Residential', rootGetters['sq/isVelocity'], rootGetters['pq/characteristics']) : [],
  defaultHardware: (state, getters, rootState, rootGetters): Hardware | null => state.filteredCatalogWrapper?.defaultHardware(rootGetters['sq/primaryNetwork'], rootGetters['cart/voice']),
  plansByGroup: (state,  _, rootState, rootGetters): Plan[] => state.filteredCatalogWrapper ? state.filteredCatalogWrapper.plans(rootGetters['sq/primaryNetwork'], rootGetters['cart/promo'],  rootGetters['sq/isBusiness'] ? 'Business' : 'Residential', state.planGroup ? state.planGroup : rootGetters['catalog/allPlanGroups'][0]) : [],
  businessBundles: (state, _, rootState, rootGetters): Plan[] => state.filteredCatalogWrapper ? state.filteredCatalogWrapper.plans(rootGetters['sq/primaryNetwork'], rootGetters['cart/promo'], 'Business') : [],
  shippingFee: (state): Catalog | null => state.filteredCatalogWrapper?.wrapper().shippingFee(),
  contractFee: (state): Catalog[] => state.filteredCatalogWrapper ? state.filteredCatalogWrapper.wrapper().contractFee() : [],
  activationFees: (state, _, rootState, rootGetters): Catalog[] => state.filteredCatalogWrapper ? state.filteredCatalogWrapper.wrapper().activationFees(rootGetters['sq/isBusiness'] ? 'Business' : 'Residential', state.planGroup, rootGetters['cart/hardware'][0]) : [],
  allFees: (state, _, rootState, rootGetters): Catalog[] => state.filteredCatalogWrapper ? state.filteredCatalogWrapper.wrapper().allFees() : [],
  defaultActivationFee: (state, _, rootState, rootGetters): Catalog | null => state.filteredCatalogWrapper?.wrapper().defaultActivationFee(rootGetters['sq/isBusiness'] ? 'Business' : 'Residential', state.planGroup, rootGetters['cart/hardware'][0]),
  defaultNoActivationFee: (state): Catalog | null => state.filteredCatalogWrapper?.wrapper().defaultActivationFee(),
  defaultNDC: (state): Catalog | null => state.filteredCatalogWrapper?.wrapper().defaultNdc(),
  defaultNCC: (state): Catalog | null => state.filteredCatalogWrapper?.wrapper().defaultNcc(),
  ndc: (state): Catalog[] => state.filteredCatalogWrapper ? state.filteredCatalogWrapper.wrapper().ndc() : [],
  promotions: (state): Catalog[] => state.filteredCatalogWrapper ? state.filteredCatalogWrapper.wrapper().promotions() : [],
  addOns: (state): Catalog[] => state.filteredCatalogWrapper ? state.filteredCatalogWrapper.wrapper().addOns() : [],
  staticIp: (state): Catalog => state.filteredCatalogWrapper ? state.filteredCatalogWrapper.wrapper().staticIp() : null,
  catalogType: state => state.catalogType,
  allPlanGroups: (state, _, rootState, rootGetters): string[] | null => state.filteredCatalogWrapper ? state.filteredCatalogWrapper.planGroups(state.filteredCatalogWrapper.plans(rootGetters['sq/primaryNetwork'], rootGetters['cart/promo'],  rootGetters['sq/isBusiness'] ? 'Business' : 'Residential')) : [],
  businessPlanGroups: (state, _, rootState, rootGetters): string[] | null => state.filteredCatalogWrapper ? state.filteredCatalogWrapper.planGroups(state.filteredCatalogWrapper.plans(rootGetters['sq/primaryNetwork'], null,  'Business')) : [],
  planGroup: state => state.planGroup,
  hardwareDescriptionForCode: state => (code: string): string | null => state.filteredCatalogWrapper ? state.filteredCatalogWrapper.wrapper().hardwareDescriptionForCode(code) : null
}

export interface Actions<S, R> extends ActionTree<S, R> {}

export const actions: Actions<State, RootState> = {
  async fetchAllProducts({ state, commit }) {
    if (state.catalogs.length <= 0) {
      await catalog
        .axios
        .get(
          `${catalog.apiUrl}${
            (process.env.catalogEndpoints as any).catalogs
          }?brand=${(process.env.brand as any).code}&excludeInactive=true`, {
            headers: {
              'Uniti-Source': unitiSource,
              'Uniti-ConversationId': unitiConversationId,
              'Uniti-TransactionId': uuidv4()
            }
          }
        )
        .then(response => {
          const catalogs = response.data.data.filter(
            a =>
              a.category.productOffering[0] &&
              a.category.productOffering[0].productSpecification.brand ===
                (process.env.brand as any).name
          )

          commit('setCatalogs', catalogs)
          commit('createCatalogWrapper', catalogs)
        })
    }
  },
}

export const mutations: MutationTree<State> = {
  setCatalogs(state: State, catalogs: CatalogResponse[]) {
    state.catalogs = catalogs
  },

  setPlanGroup(state: State, group: string) {
    state.planGroup = group
  },

  createCatalogWrapper(state: State, catalogs: CatalogResponse[]) {
    const brand: Brand = (process.env.brand as any).code
    state.filteredCatalogWrapper = (new FilteredCatalogWrapper(new CatalogWrapper(catalogs, networks, (process.env.carrierCodes as any)), brand))
  }
}
