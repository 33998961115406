import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { RootState } from 'store'

export const name = 'signup'

export const namespaced = true

export interface Stage {
  step: number
  name: string
  componentKey: string
}

export interface State {
  stages: Stage[]
  relocationStages: Stage[]
  newServiceStages: Stage[]
  currentStage: Stage,
  portingStage: Stage,
  needPorting: boolean,
  validationErrors: any
}

const getBrand = process.env.brand as any

const CONTRACT_LENGTH_ERROR_MESSAGE = 'Please select a contract term'
// const CONTRACT_ACCEPTED_ERROR_MESSAGE = 'Please accept contract to continue'
const PLAN_ERROR_MESSAGE = 'Please select a plan speed'
const CONTACT_ERROR_MESSAGE_FIRST_NAME = 'Please provide a valid first name'
const CONTACT_ERROR_MESSAGE_LAST_NAME = 'Please provide a valid last name'
const CONTACT_ERROR_MESSAGE_EMAIL = 'Please provide a valid email'
const CONTACT_ERROR_MESSAGE_PHONE = 'Please provide a valid phone number'
const CONTACT_ERROR_MESSAGE_SHIPPING = 'Please provide a valid shipping address'
const CONTACT_ERROR_MESSAGE_SERVICE_ADDRESS =
  'Please confirm your service address to continue'
const CONTACT_ERROR_MESSAGE_VOIP_ADDRESS = `Please contact our sales team at ${
  getBrand.phoneSales
} to process your VoIP order.`
const CREDIT_CARD_ERROR_MESSAGE_FIRST_NAME = 'Please provide a valid first name'
const CREDIT_CARD_ERROR_MESSAGE_LAST_NAME = 'Please provide a valid last name'
const BANK_ACCOUNT_ERROR_MESSAGE_ACCOUNT_NAME = 'Please provide a valid account name'
const BANK_ACCOUNT_ERROR_MESSAGE_ACCOUNT_NUMBER = 'Please provide a valid account number'
const BANK_ACCOUNT_ERROR_MESSAGE_BSB = 'Please provide a valid BSB number'
const TERMS_ACCEPTED_ERROR_MESSAGE =
  'Please accept terms and conditions to continue'
const CONTACT_ERROR_MESSAGE_ACTIVATION = 'Please provide an activation date'
const CONTACT_ERROR_MESSAGE_ACTIVATION_DATE =
  'Please provide an activation date'
const CONTACT_ERROR_MESSAGE_MEMBER_ID = 'Please provide a valid RAA Card Number'
const VOIP_ERROR_MESSAGE_FNN = 'Please provide a valid phone number'
const VOIP_ERROR_MESSAGE_PROVIDER = 'Please provide a valid Phone Provider'
const VOIP_ERROR_MESSAGE_ACCOUNT = 'Please provide a valid Account Name'
const VOIP_ERROR_MESSAGE_NUMBER = 'Please provide a valid Account Number'
const VOIP_ERROR_MESSAGE_AGREEMENT = 'Please agree to the VoIP Terms and Conditions.'
const CONTACT_ERROR_MESSAGE_BUSINESS = 'Please provide a valid Business Name and ABN'

export const state = (): State => ({
  stages: [
    {
      step: 1,
      name: 'Contact Details',
      componentKey: 'contact-details'
    },
    {
      step: 2,
      name: 'Payment Details',
      componentKey: 'payment-details'
    }
  ],
  relocationStages: [
    {
      step: 1,
      name: 'Contact Details',
      componentKey: 'contact-details'
    },
    {
      step: 2,
      name: 'Relocation Details',
      componentKey: 'relocation-details'
    }
  ],
  newServiceStages: [
    {
      step: 1,
      name: 'Contact Details',
      componentKey: 'contact-details'
    },
    {
      step: 2,
      name: 'New Service Details',
      componentKey: 'new-service-details'
    }
  ],
  currentStage: {
    step: 1,
    name: 'Contact Details',
    componentKey: 'contact-details'
  },
  portingStage: {
    step: 2,
    name: 'Porting Details',
    componentKey: 'porting-details'
  },
  needPorting: false,
  validationErrors: {
    contract: {
      length: '',
      accepted: ''
    },
    plan: '',
    contact: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      confirmServiceAddress: '',
      shippingAddress: '',
      activation: '',
      activationDate: '',
      memberId: '',
      businessName: '',
      abn: ''
    },
    address: {
      unitNumber: '',
      houseNumber: '',
      street: '',
      suburb: '',
      postcode: '',
      state: '',
      shippingAddress: ''
    },
    card: {
      firstName: '',
      lastName: '',
      errors: ''
    },
    bankAccount: {
      name: '',
      number: '',
      bsb: '',
      errors: ''
    },
    porting: {
      fnn: '',
      provider: '',
      accountNumber: '',
      accountName: '',
      agreement: ''
    }
  }
})

export const getters: GetterTree<State, RootState> = {
  stages: state => state.stages,
  currentStage: state => state.currentStage,
  validationErrors: state => state.validationErrors,
  contactErrors: state => state.validationErrors.contact,
  contractErrors: state => state.validationErrors.contract.accepted,
  cardErrors: state => state.validationErrors.card,
  addressErrors: state => state.validationErrors.address,
  bankAccountErrors: state => state.validationErrors.bankAccount,
  portingErrors: state => state.validationErrors.porting
}

export interface Actions<S, R> extends ActionTree<S, R> {}
export const actions: Actions<State, RootState> = {}

export const mutations: MutationTree<State> = {
  nextStep(state: State) {
    if (state.currentStage.step < state.stages.length) {
      state.currentStage = state.stages[state.currentStage.step]
    }
  },

  switchStages(state: State) {
    state.stages = state.relocationStages
  },

  switchNewServiceStages(state: State) {
    state.stages = state.newServiceStages
  },

  setCurrentStage(state: State, step: Stage) {
    state.currentStage = step
  },

  setPlanContractError(state: State) {
    state.validationErrors.contract.length = CONTRACT_LENGTH_ERROR_MESSAGE
  },

  clearPlanContractError(state: State) {
    state.validationErrors.contract.length = ''
  },

  setContractAcceptedError(state: State) {
    state.validationErrors.contract.accepted = TERMS_ACCEPTED_ERROR_MESSAGE
  },

  clearContractAcceptedError(state: State) {
    state.validationErrors.contract.accepted = ''
  },

  setPlanError(state: State) {
    state.validationErrors.plan = PLAN_ERROR_MESSAGE
  },

  clearPlanError(state: State) {
    state.validationErrors.plan = ''
  },

  setFirstNameError(state: State) {
    state.validationErrors.contact.firstName = CONTACT_ERROR_MESSAGE_FIRST_NAME
  },

  clearFirstNameError(state: State) {
    state.validationErrors.contact.firstName = ''
  },

  setActivationError(state: State) {
    state.validationErrors.contact.activation = CONTACT_ERROR_MESSAGE_ACTIVATION
  },

  clearActivationError(state: State) {
    state.validationErrors.contact.activation = ''
  },

  setActivationDateError(state: State) {
    state.validationErrors.contact.activationDate = CONTACT_ERROR_MESSAGE_ACTIVATION_DATE
  },

  clearActivationDateError(state: State) {
    state.validationErrors.contact.activationDate = ''
  },

  setLastNameError(state: State) {
    state.validationErrors.contact.lastName = CONTACT_ERROR_MESSAGE_LAST_NAME
  },

  clearLastNameError(state: State) {
    state.validationErrors.contact.lastName = ''
  },

  setEmailError(state: State) {
    state.validationErrors.contact.email = CONTACT_ERROR_MESSAGE_EMAIL
  },

  clearEmailError(state: State) {
    state.validationErrors.contact.email = ''
  },
  
  setBusinessError(state: State) {
    state.validationErrors.contact.businessName = CONTACT_ERROR_MESSAGE_BUSINESS
  },

  clearBusinessError(state: State) {
    state.validationErrors.contact.businessName = ''
  },

  setPhoneError(state: State) {
    state.validationErrors.contact.phone = CONTACT_ERROR_MESSAGE_PHONE
  },

  clearPhoneError(state: State) {
    state.validationErrors.contact.phone = ''
  },

  setShippingAddressError(state: State) {
    state.validationErrors.contact.shippingAddress = CONTACT_ERROR_MESSAGE_SHIPPING
  },

  clearShippingAddressError(state: State) {
    state.validationErrors.contact.shippingAddress = ''
  },

  setConfirmServiceAddressError(state: State) {
    state.validationErrors.contact.confirmServiceAddress = CONTACT_ERROR_MESSAGE_SERVICE_ADDRESS
  },

  clearConfirmServiceAddressError(state: State) {
    state.validationErrors.contact.confirmServiceAddress = ''
  },

  setConfirmServiceAddressVoipError(state: State) {
    state.validationErrors.contact.confirmServiceAddress = CONTACT_ERROR_MESSAGE_VOIP_ADDRESS
  },

  setCardFirstNameError(state: State) {
    state.validationErrors.card.firstName = CREDIT_CARD_ERROR_MESSAGE_FIRST_NAME
  },

  clearCardFirstNameError(state: State) {
    state.validationErrors.card.firstName = ''
  },

  setCardLastNameError(state: State) {
    state.validationErrors.card.lastName = CREDIT_CARD_ERROR_MESSAGE_LAST_NAME
  },

  clearCardLastNameError(state: State) {
    state.validationErrors.card.lastName = ''
  },

  setCardErrors(state: State, errors: any) {
    state.validationErrors.card.errors = errors
  },

  clearCardErrors(state: State) {
    state.validationErrors.card = ''
  },

  setBankAccountNameError(state: State) {
    state.validationErrors.bankAccount.name = BANK_ACCOUNT_ERROR_MESSAGE_ACCOUNT_NAME
  },

  clearBankAccountNameError(state: State) {
    state.validationErrors.bankAccount.name = ''
  },

  setBankAccountNumberError(state: State) {
    state.validationErrors.bankAccount.number = BANK_ACCOUNT_ERROR_MESSAGE_ACCOUNT_NUMBER
  },

  clearBankAccountNumberError(state: State) {
    state.validationErrors.bankAccount.number = ''
  },

  setBankAccountBsbError(state: State) {
    state.validationErrors.bankAccount.bsb = BANK_ACCOUNT_ERROR_MESSAGE_BSB
  },

  clearBankAccountBsbError(state: State) {
    state.validationErrors.bankAccount.bsb = ''
  },

  setBankAccountErrors(state: State, errors: any) {
    state.validationErrors.bankAccount.errors = errors
  },

  clearBankAccountErrors(state: State) {
    state.validationErrors.bankAccount = ''
  },

  setAddressError(state: State, addressField: String) {
    switch (addressField) {
      case 'houseNumber':
        state.validationErrors.address.houseNumber = 'House Number Required'
        break
      case 'street':
        state.validationErrors.address.street = 'Street Required'
        break
      case 'suburb':
        state.validationErrors.address.suburb = 'Suburb Required'
        break
      case 'postcode':
        state.validationErrors.address.postcode = 'Postcode Required'
        break
      case 'state':
        state.validationErrors.address.state = 'State Required'
        break
      default:
        break
    }
  },

  clearAddressError(state: State, addressField: String) {
    switch (addressField) {
      case 'houseNumber':
        state.validationErrors.address.houseNumber = ''
        break
      case 'street':
        state.validationErrors.address.street = ''
        break
      case 'suburb':
        state.validationErrors.address.suburb = ''
        break
      case 'postcode':
        state.validationErrors.address.postcode = ''
        break
      case 'state':
        state.validationErrors.address.state = ''
        break
      default:
        break
    }
  },

  setMemberIdError(state: State) {
    state.validationErrors.contact.memberId = CONTACT_ERROR_MESSAGE_MEMBER_ID
  },

  clearMemberIdError(state: State) {
    state.validationErrors.contact.memberId = ''
  },
  
  insertPortingStep(state: State) {
    const newSteps = state.stages
    const lastStep = Object.values(newSteps).at(-1)
    
    if (!newSteps.find(st => st.name === "Porting Details")) {
      newSteps.splice(1, 1, state.portingStage)
      lastStep.step += 1
      newSteps.push(lastStep)
      state.stages = newSteps
    }
  },
  
  removePortingStep(state: State) {
    const newSteps = state.stages
    const lastStep = Object.values(newSteps).at(-1)
    if (newSteps.find(st => st.name === "Porting Details")) {
      newSteps.splice(1, 2)
      lastStep.step -= 1
      newSteps.push(lastStep)  
      state.stages = newSteps
    }
  },
  
  setVoipFnnError(state: State) {
    state.validationErrors.porting.fnn = VOIP_ERROR_MESSAGE_FNN
  },
  
  setVoipProviderError(state: State) {
    state.validationErrors.porting.provider = VOIP_ERROR_MESSAGE_PROVIDER
  },
  
  setVoipAccountNameError(state: State) {
    state.validationErrors.porting.accountName = VOIP_ERROR_MESSAGE_ACCOUNT
  },
  
  setVoipAccountNumberError(state: State) {
    state.validationErrors.porting.accountNumber = VOIP_ERROR_MESSAGE_NUMBER
  },
  
  setVoipAgreementError(state: State) {
    state.validationErrors.porting.agreement = VOIP_ERROR_MESSAGE_AGREEMENT
  },

  clearVoipErrors(state: State) {
    state.validationErrors.porting = {
      'fnn' : '',
      'voipPorting' : '',
      'accountName' : '',
      'accountNumber' : '',
      'agreement': ''
    }
  }
}
